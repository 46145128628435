export default { 
    head: {
        title: '',
        title2: '惠采购电子交易平台',
        citycode:'',//城市编号
        planName:'',
        dljgLoginUrl:'',
        tbrLoginUrl:'',
        zjLoginUrl:'',
        meta: {
            description: '交易平台,招投标,电子交易平台',
            keywords: '交易平台,招投标,电子交易平台',
        },
        icon: '/favicon.ico',
        logo:'',
    },
    plugins: {
        element: true,
        myComs: true,
        vuexStorage: false,
        jquery: true,
        sentry: false,
        cnzz: false
    },
    proxy: {
        '/web': {
            target: 'http://192.168.1.104:9041',
            // http://123.182.227.105:10046/
            // http://192.168.1.246:8051/
            changeOrigin: true,
            pathRewrite: {
                '^/': '/'
            }
        },
        '/standalonfile': {
            target: 'http://192.168.1.246:20006',
            // http://123.182.227.105:10046/
            // http://192.168.1.246:8051/
            changeOrigin: true,
            pathRewrite: {
                '^/': '/'
            }
        },
    },
}
