import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _325c93f8 = () => interopDefault(import('..\\pages\\about\\index.vue' /* webpackChunkName: "pages/about/index" */))
const _7b9b368f = () => interopDefault(import('..\\pages\\business\\index.vue' /* webpackChunkName: "pages/business/index" */))
const _d24b1aee = () => interopDefault(import('..\\pages\\cahandle\\index.vue' /* webpackChunkName: "pages/cahandle/index" */))
const _0c5960c7 = () => interopDefault(import('..\\pages\\download\\index.vue' /* webpackChunkName: "pages/download/index" */))
const _dc7c5dae = () => interopDefault(import('..\\pages\\guide\\index.vue' /* webpackChunkName: "pages/guide/index" */))
const _0889069c = () => interopDefault(import('..\\pages\\login\\index.vue' /* webpackChunkName: "pages/login/index" */))
const _676767c8 = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _74f4e7a3 = () => interopDefault(import('..\\pages\\outside-cjwt\\index.vue' /* webpackChunkName: "pages/outside-cjwt/index" */))
const _2f98abcc = () => interopDefault(import('..\\pages\\register\\index.vue' /* webpackChunkName: "pages/register/index" */))
const _dfc2aa38 = () => interopDefault(import('..\\pages\\business\\detail.vue' /* webpackChunkName: "pages/business/detail" */))
const _25c52f0a = () => interopDefault(import('..\\pages\\business\\detail-sd.vue' /* webpackChunkName: "pages/business/detail-sd" */))
const _9cb243d4 = () => interopDefault(import('..\\pages\\business\\index copy.vue' /* webpackChunkName: "pages/business/index copy" */))
const _2079fcd1 = () => interopDefault(import('..\\pages\\login\\index-zfcg.vue' /* webpackChunkName: "pages/login/index-zfcg" */))
const _559046f7 = () => interopDefault(import('..\\pages\\news\\detail.vue' /* webpackChunkName: "pages/news/detail" */))
const _19a625c0 = () => interopDefault(import('..\\pages\\business\\components\\file-download.vue' /* webpackChunkName: "pages/business/components/file-download" */))
const _16561d22 = () => interopDefault(import('..\\pages\\business\\components\\link-paper.vue' /* webpackChunkName: "pages/business/components/link-paper" */))
const _17c8ab17 = () => interopDefault(import('..\\pages\\business\\components\\search-bar.vue' /* webpackChunkName: "pages/business/components/search-bar" */))
const _f85602e4 = () => interopDefault(import('..\\pages\\business\\components\\search-bar copy.vue' /* webpackChunkName: "pages/business/components/search-bar copy" */))
const _4393b194 = () => interopDefault(import('..\\pages\\business\\components\\table-hxrgs.vue' /* webpackChunkName: "pages/business/components/table-hxrgs" */))
const _77a4229f = () => interopDefault(import('..\\pages\\register\\components\\btn-yzm.vue' /* webpackChunkName: "pages/register/components/btn-yzm" */))
const _37e8531e = () => interopDefault(import('..\\pages\\register\\components\\captcha.vue' /* webpackChunkName: "pages/register/components/captcha" */))
const _2cce1b0d = () => interopDefault(import('..\\pages\\register\\components\\input-yzm.vue' /* webpackChunkName: "pages/register/components/input-yzm" */))
const _f1694e08 = () => interopDefault(import('..\\pages\\register\\components\\tos.vue' /* webpackChunkName: "pages/register/components/tos" */))
const _2cd6740f = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _fe59a4ae = () => interopDefault(import('..\\pages\\index\\sections\\banner.vue' /* webpackChunkName: "pages/index/sections/banner" */))
const _f139c206 = () => interopDefault(import('..\\pages\\index\\sections\\business.vue' /* webpackChunkName: "pages/index/sections/business" */))
const _e2d598d0 = () => interopDefault(import('..\\pages\\index\\sections\\enters.vue' /* webpackChunkName: "pages/index/sections/enters" */))
const _9d13beb6 = () => interopDefault(import('..\\pages\\index\\sections\\footlink.vue' /* webpackChunkName: "pages/index/sections/footlink" */))
const _5731fda7 = () => interopDefault(import('..\\pages\\index\\sections\\logins.vue' /* webpackChunkName: "pages/index/sections/logins" */))
const _3ec24310 = () => interopDefault(import('..\\pages\\index\\sections\\news.vue' /* webpackChunkName: "pages/index/sections/news" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _325c93f8,
    alias: "/about/index.html",
    name: "about"
  }, {
    path: "/business",
    component: _7b9b368f,
    alias: "/business/index.html",
    name: "business"
  }, {
    path: "/cahandle",
    component: _d24b1aee,
    alias: "/cahandle/index.html",
    name: "cahandle"
  }, {
    path: "/download",
    component: _0c5960c7,
    alias: "/download/index.html",
    name: "download"
  }, {
    path: "/guide",
    component: _dc7c5dae,
    alias: "/guide/index.html",
    name: "guide"
  }, {
    path: "/login",
    component: _0889069c,
    alias: "/login/index.html",
    name: "login"
  }, {
    path: "/news",
    component: _676767c8,
    alias: "/news/index.html",
    name: "news"
  }, {
    path: "/outside-cjwt",
    component: _74f4e7a3,
    alias: "/outside-cjwt/index.html",
    name: "outside-cjwt"
  }, {
    path: "/register",
    component: _2f98abcc,
    alias: "/register/index.html",
    name: "register"
  }, {
    path: "/business/detail",
    component: _dfc2aa38,
    alias: "/business/detail/index.html",
    name: "business-detail"
  }, {
    path: "/business/detail-sd",
    component: _25c52f0a,
    alias: "/business/detail-sd/index.html",
    name: "business-detail-sd"
  }, {
    path: "/business/index%20copy",
    component: _9cb243d4,
    alias: "/business/index%20copy/index.html",
    name: "business-index copy"
  }, {
    path: "/login/index-zfcg",
    component: _2079fcd1,
    alias: "/login/index-zfcg/index.html",
    name: "login-index-zfcg"
  }, {
    path: "/news/detail",
    component: _559046f7,
    alias: "/news/detail/index.html",
    name: "news-detail"
  }, {
    path: "/business/components/file-download",
    component: _19a625c0,
    alias: "/business/components/file-download/index.html",
    name: "business-components-file-download"
  }, {
    path: "/business/components/link-paper",
    component: _16561d22,
    alias: "/business/components/link-paper/index.html",
    name: "business-components-link-paper"
  }, {
    path: "/business/components/search-bar",
    component: _17c8ab17,
    alias: "/business/components/search-bar/index.html",
    name: "business-components-search-bar"
  }, {
    path: "/business/components/search-bar%20copy",
    component: _f85602e4,
    alias: "/business/components/search-bar%20copy/index.html",
    name: "business-components-search-bar copy"
  }, {
    path: "/business/components/table-hxrgs",
    component: _4393b194,
    alias: "/business/components/table-hxrgs/index.html",
    name: "business-components-table-hxrgs"
  }, {
    path: "/register/components/btn-yzm",
    component: _77a4229f,
    alias: "/register/components/btn-yzm/index.html",
    name: "register-components-btn-yzm"
  }, {
    path: "/register/components/captcha",
    component: _37e8531e,
    alias: "/register/components/captcha/index.html",
    name: "register-components-captcha"
  }, {
    path: "/register/components/input-yzm",
    component: _2cce1b0d,
    alias: "/register/components/input-yzm/index.html",
    name: "register-components-input-yzm"
  }, {
    path: "/register/components/tos",
    component: _f1694e08,
    alias: "/register/components/tos/index.html",
    name: "register-components-tos"
  }, {
    path: "/",
    component: _2cd6740f,
    alias: "/index.html",
    name: "index",
    children: [{
      path: "sections/banner",
      component: _fe59a4ae,
      name: "index-sections-banner"
    }, {
      path: "sections/business",
      component: _f139c206,
      name: "index-sections-business"
    }, {
      path: "sections/enters",
      component: _e2d598d0,
      name: "index-sections-enters"
    }, {
      path: "sections/footlink",
      component: _9d13beb6,
      name: "index-sections-footlink"
    }, {
      path: "sections/logins",
      component: _5731fda7,
      name: "index-sections-logins"
    }, {
      path: "sections/news",
      component: _3ec24310,
      name: "index-sections-news"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
