export const state = () => ({
    config: {
        nav: [
            {
                title: '首页',
                href: '/',
                activeReg: /(^\/search*)|(^\/$)/,
            },
            {
                title: '新闻公告',
                href: '/news',
                activeReg: /(^\/news*)/,
            },
            {
                title: '交易信息',
                href: '/business',
                activeReg: /(^\/business*)/,
            },
            {
                title: '帮助中心',
                href: '/guide',
                activeReg: /(^\/guide*)/,
            },
            {
                title: '下载中心',
                href: '/download',
                activeReg: /(^\/download*)/,
            },
           
            {
                title: 'CA办理/续费',
                href: '/cahandle',
                activeReg: /(^\/cahandle*)/,
            },
            {
                title: '关于我们',
                href: '/about',
                activeReg: /(^\/about*)/,
            },
        ],
        linkArr:[
            
        ],
        
    }
})

export const mutations = {
    
}
